import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={4} title={'Hackaviz'} description={'Prêt, partez, visualisez !'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`Le concept`}</h1>
        <p>{`Depuis plusieurs années, l’association Toulouse DataViz organise un concours de visualisation : l’`}<strong parentName="p">{`HACKAVIZ`}</strong>{`, qui consiste à raconter une histoire avec des graphiques à partir d’un jeu de données open data original. Cet événement est accessible à tous (amateur ou professionnel) en solo ou en équipe.
Il se déroule sur un temps limité (10 jours) à l’issue duquel un jury professionnel délibère pour attribuer les prix lors d’une grande soirée.`}</p>
        <h1>{`Le règlement`}</h1>
        <Button link={'/hackaviz/reglement'} type={'internal'} text={'Voir le règlement'} mdxType="Button" />
        <h1>{`Hackaviz sur mesure`}</h1>
        <p>{`Nous créons aussi des Hackaviz sur mesure.`}</p>
        <Button link={'/offres/hackaviz'} type={'internal'} text={'En savoir plus'} mdxType="Button" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      